.bottom-background {
    padding: 50px;
    background-color: #282c34;
    display: inline-flex;  
    height:180px;
    width: 100%;
}

.App-logo-bottom {
    width: 120px;
    height: 120px;
}

.bottom-stytle {
    width: 100%;
    height: 50px;
    align-items: center!important;
    justify-content: center!important;
}

.bottom-title {
    width: auto;
    height: 60px;
    font-size: 16px;
    margin-left: 0px;
    margin-top: 10px;
    text-align: middle;
    font-family:Arial, Helvetica, sans-serif;
    color:white;
}

.bottom-sub-title {
    font-size: 14px;
    margin-top: 10px;
    text-align: middle;
    color:white;
}