.App-logo-style {
    width: 100px;
    height: 100px;
}

.App-title {
    padding: 50px;
    background-color: #282c34;
    display: inline-flex;  
    height:60px;
    width: 100%;
}