.grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 3列，每列占用1部分 */
    gap: 50px; /* 格子间隔 */
    padding: 100px;
    align-items: center;
  }
   
.grid-item {
    margin-top: 50px;
    background-color:chartreuse; /* 格子背景色 */
    border: 2px solid #ccc; /* 格子边框 */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* 格子内部填充 */
    width: 350px;
    height: 350px;
    font-size: 30px;
}

.grid-single-container {
    width: 100%;
    height: auto;
    /* margin: 100px; */
    align-items: center;
}

.grid-single-item {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left:42%;
    background-color:transparent; /* 格子背景色 */
    border: 2px solid #ccc; /* 格子边框 */
    border-radius: 5px;
    display: flex;
    font-size: 30px;
    width: 350px;
    height: 350px;
    align-items: center;
    position: relative;
}

.grid-sinle-name {
  top: 300px;
  width: 100%;
  height: fit-content;
  align-items: center;
  position: absolute;
  z-index: 2;
  font-size: 18px;
}

.mini-game-icon{
  top: 70px;
  left: 0px;
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: 1;
}