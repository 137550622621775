.area-layout {
  display: inline;
}

.game-platform-name {
   width: auto;
   height: auto;
   font-size: 35px;
   text-align: left;
   margin-top: 50px;
   margin-left: 100px;
   margin-bottom: 0px;
}
.game-grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 3列，每列占用1部分 */
    gap: 50px; /* 格子间隔 */
    padding: 100px;
    padding-top: 20px;
    align-items: center;
  }
   
.game-grid-item {
    margin-top: 20px;
    background-color: transparent; /* 格子背景色 */
    border: 2px solid #ccc; /* 格子边框 */
    border-radius: 25px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    padding: 20px; /* 格子内部填充 */
    width: 200px;
    height: 200px;
    font-size: 20px;
    /* display: inline-flex;   */
}

.game-icon {
  width: 150px;
  height: 150px;
}

.game-name {
  margin-top: 30px;
  font-size: 18;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}