
.title-style{
    width: 90%;
    height: 150px;
    text-align: right!important;
}

.date-time {
    font-size: 30px;
}

.logo-style{
    display: inline;
    background-image: url("../../images/home/wigamefun-logo.jpg");
    padding: 50px;
    width: 50px;
    height: 50px;
}

.title {
    width: auto;
    height: 60px;
    font-size: 20px;
    margin-left: 20px;
    margin-top: 10px;
    text-align: middle;
    font-family:Arial, Helvetica, sans-serif;
    color:cadetblue;
}

.sub-title {
    font-size: 15px;
    margin-top: 10px;
    text-align: middle;
    color:cadetblue;
}