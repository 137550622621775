.tables{
    padding: 50px;
    background-color: #282c34;
    display: inline-flex;  
    height:40px;
    width: 100%;
    text-decoration: none;
}

.tab-list {
    text-decoration: none;
}

.tab-name{
    color:cadetblue;
    font-size: 40px;
    text-decoration: none;
}